@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.center{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team_logo{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.team_logo img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.add_team_logo{
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.add_team_logo img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.card-img-top{
  width: 100%;
  height: 180px;
}

.card-img-top img{
  width: 100%;
  height: 100%;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.footer{
  position: fixed;
  bottom: 0;
}

.navbar-nav .nav-link{
  color: #f65e16 !important;
}

.navbar-nav .nav-link.active{
  color: #FFF !important;
}

.navbar-toggler{
  background-color: #FFF;
}

.largerCheckbox{
  width: 18px;
  height: 18px;
}

.main-title{
  color: #f65e16;
}

.modal-team-detail{
  max-width: 80%;
}

.error-text{
  color: rgb(209, 8, 8);
}

.qr-scan{
  width: 150px;
  height: 150px;
  position: fixed;
  top: 20%;
  left: 39%;
  z-index: 2147483648;
  background-color: #FFF;
  border: 1px solid #000;
}

.razorpay-container{
  z-index: 2147483646!important;
}

@media only screen and (max-width: 600px) {
  .center {
    height: 100vh;
    display: block;
  }
  .login_img{
    width: 100%;
    margin: 15px 0px;
  }
  .qr-scan{
    top: 13%;
    left: 4%;
  }
  .login-form{
    width: 100% !important;
  }
  .modal-team-detail{
    max-width: 100%;
  }
}